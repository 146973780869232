@use "../config/" as *;
.sticky-ranking {
    > div {
        @extend %p-zero;
    }
}
.waf-standings,
.waf-statslisting {
    @extend %white-bg;
    @extend %p-zero;
    @each $rank,
    $rank-key in $ranks {
        .#{$rank} {
            flex: inherit;
            width: auto;
            .table-data-wrap {
                &::after {
                    content: '';
                    width: 5rem;
                    aspect-ratio: 1/1;
                    background:url(/static-assets/images/svg/#{$rank-key}.svg?v=#{$image-version}) no-repeat center;
                    background-size: contain;
                    @extend %flex;
                }
            }
        }
    }
    .table {
        overflow: hidden;
        @extend %flex;
        &-right {
            @extend %w-40;
            .table-head {
                .table-data {
                    height: 4rem;
                    @extend %c-blue-bg-10;
                }
            }
            .table-data {
                width: 5rem;
                flex-shrink: 0;
                justify-content: center;
            }
        }
        &-left {
            @extend %w-60;
            .table-row {
                &::after {
                    content: "";
                    filter: drop-shadow(0.1rem 0 0.4rem hsl(var(--hsl-c-pure-black)/ 0.25));
                    height: 100%;
                    width: 0.2rem;
                    display: block;
                    @include position(null, 0);
                    @include bg(c-blue, 0.8);
                }
            }
        }
        &-head {
            .text {
                @extend %c-light-grey-1-10;
            }
        }
        &-data-wrap {
            @extend %w-100;
            @extend %flex-n-c;
        }
        &-body {
            .table-row {
                .table-data {
                    @include border(1, c-blue, 1, bottom);
                }
                &:nth-child(odd) {
                    .table-data {
                        background: hsla(var(--hsl-c-light-grey-1)/0.2);
                    }
                }
            }
            .rank {
                .text {
                    @extend %c-black-6;
                }
            }
        }
        &-row {
            border-bottom: 0;
        }
        .image {
            width: 2.4rem;
            @extend %circle-radius;
            @extend %ratio-1-1;
            @extend %m-r-half;
            // @extend %c-blue-1-bg-10;
            @extend %c-blue-bg-10;
            // @include border(1,c-blue,1);
        }
    }
    .team-info {
        width: calc(100% - 3.2rem);
        @extend %d-block;
        @extend %text-left;
        .name {
            font-size: 1.2rem;
            line-height: 1.2;
            @extend %capitalize;
        }
    }
    .name {
        @extend %w-100;
        @extend %d-block;
        // @extend %font-14-psb;
        @extend %c-blue-10;
    }
    .origin {
        @include truncate-text(1);
        @extend %font-10-pr;
        @extend %capitalize;
    }
    .logo {
        width: 1.6rem;
        min-width: 1.6rem;
        height: 1.6rem;
        @extend %ratio-1-1;
    }
    .country-info {
        padding-top: .5rem;
        gap: var(--half-space);
        @extend %flex-n-c;
    }
    .total {
        .text {
            font-family: $font-pb;
        }
    }
    .rank {
        justify-content: center;
        width: 6rem;
        .text {
            @extend %c-blue-8;
        }
    }
    .team {
        width: calc(100% - 6rem);
    }
    &.group {
        @extend %p-x-half;
        .table {
            &:not(:last-child) {
                @extend %m-b-two-space;
            }
            .team {
                .image {
                    @extend %c-white-bg-10;
                }
                img[src=""] {
                    @extend %c-blue-bg-10;
                }
            }
        }
        .table-responsive {
            &:not(:last-child) {
                margin-bottom: var(--three-space);
            }
        }
    }
    .btn-fill {
        @include flex-config(inline-flex, null, null, center);
        &:after {
            @include icon("\e809", 24);
            line-height: 1;
        }
    }
}
.athletes-rank {
    .table {
        &-left {
            @extend %w-100;
        }
        .image {
            // @include border(1,c-blue,1);
            border: 0;
            @extend %c-blue-bg-10;
            @extend %circle-radius;
        }
        .table-body {
            .table-data {
                .table-data-wrap > a {
                    @extend %flex-n-c;
                }
            }
        }
        .team-info {
            a {
                display: block;
                width: max-content;
            }
            .name {
                width: fit-content;
            }
        }
    }
}
.athletes-rank,
.waf-statslisting {
    .filter-section {
        .filter {
            &-search,
            &-result-list,
            &-hide-data {
                @extend %d-none;
            }
        }
    }
}
.waf-statslisting {
    .table {
        &-right {
            .table-head {
                .table-data {
                    .text {
                        line-height: 1.2rem;
                    }
                }
                .uww-pts {
                    .text {
                        @extend %c-white-10;
                    }
                }
            }
        }
        &-left {
            .table-body {
                .team-info {
                    .name {
                        line-height: 1.6rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .country-info {
                        .origin {
                            flex-shrink: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
                .table-data {
                    .table-data-wrap > a {
                        @extend %flex-n-c;
                    }
                }
            }
        }
    }
    .tag {
        line-height: 1.5;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @extend %c-orange-10;
        @extend %font-12;
        &::before {
            content: "|";
            margin-inline: -.4rem var(--quarter-space);
            display: inline-block;
            @extend %c-grey-10;
        }
    }
    .logo {
        vertical-align: text-bottom;
    }
    .origin {
        @extend %c-blue-1-10;
    }
}
.group {
    .table {
        @extend %half-radius;
        &-left {
            width: 55%;
        }
        &-right {
            width: 45%;
            .table-data {
                flex: 1;
                .text {
                    line-height: 1;
                }
            }
            .table-data-wrap {
                justify-content: center;
            }
        }
        &-body {
            .rank {
                width: unset;
                padding-right: var(--quarter-space);
            }
            .team {
                width: 100%;
            }
        }
        &-head {
            .team {
                padding-left: var(--full-space);
            }
        }
    }
}
.team-ranking {
    &.waf-standings {
        .table {
            @extend %half-radius;
            .team {
                .image {
                    @extend %c-white-bg-10;
                }
                img[src=""] {
                    @extend %c-blue-bg-10;
                }
            }
        }
        .image {
            border: 0;
        }
        .team-info {
            .name {
                @extend %capitalize;
                @include truncate-vertical(1, 12);
                line-height: 1.8rem;
            }
            .origin {
                @extend %uppercase;
            }
        }
        .table-left {
            .table-head {
                .text {
                    font-family: $font-pb;
                }
            }
        }
    }
}
.waf-standings {
    .table-head {
        height: 6.5rem;
    }
    .table-right .table-head .table-data {
        height: 6.5rem;
    }
    .table-title {
        line-height: 1.2;
        @extend %m-t-zero;
        @extend %c-blue-10;
        @extend %font-20-pr;
    }
    .table-responsive:not(:last-child) {
        @extend %m-b-one-n-half;
    }
    .action-container {
        margin-top: 4rem;
        @extend %text-center;
        .btn-fill {
            @extend %c-blue-bg-10;
            @extend %c-white-10;
            &:hover {
                @extend %c-orange-bg-10;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-standings,
    .waf-statslisting {
        @include white-bg();
        .table {
            &-left {
                width: 30%;
                .table-row {
                    &::after {
                        content: unset;
                    }
                }
            }
            &-right {
                width: 70%;
                .table-head {
                    @include border-radius(0 var(--half-radius) 0 0, hidden);
                }
                .table-data {
                    width: auto;
                    flex: 1;
                }
            }
            &-data {
                width: auto;
                flex: 1;
                justify-content: flex-start;
                padding-inline: var(--half-space);
            }
            &-head {
                @include border-radius(var(--half-radius) 0 0 0);
            }
        }
        .table-body {
            .rank {
                .text {
                    font-size: 1.4rem;
                }
            }
        }
        .table-data {
            height: 9.4rem;
        }
        .rank {
            max-width: 10rem;
        }
        .team-info {
            .name {
                font: 1.6rem $font-pb;
            }
        }
        .name {
            @include font(16);
        }
        .origin {
            @include font(12);
            @include color(c-blue-1, 10);
        }
        .btn-fill {
            font-size: 1.6rem;
        }
        .filter-section {
            margin-top: var(--two-space);
            .filter {
                &-search,
                &-label-group {
                    display: none;
                }
                &-body {
                    flex-basis: 100%;
                }
                &-hide-data {
                    top: 30%;
                    width: 16%;
                }
            }
        }
    }
    .group {
        .table {
            .image {
                margin-right: var(--full-space);
            }
            &-head {
                .team {
                    padding-left: var(--half-space);
                }
            }
            &-body {
                .rank {
                    width: auto;
                    padding-right: var(--half-space);
                }
                .team {
                    width: auto
                }
            }
            &-right {
                .table-body {
                    .text {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
    .waf-standings {
        &.athletes-rank {
            .table-left {
                width: 100%;
            }
        }
    }
    .athletes-rank {
        .table {
            &-head {
                @include border-radius(var(--half-radius) var(--half-radius) 0 0);
            }
            &-body .rank .table-data-wrap {
                width: 3rem;
                justify-content: center;
            }
            &-left {
                width: 100%;
            }
            .image {
                margin-right: var(--one-n-half-space);
            }
        }
    }
    .athletes-rank,
    .waf-statslisting {
        .filter-section {
            .secondary {
                width: 100%;
            }
            .label-group-head {
                display: none;
            }
            .filter-label-group {
                display: flex;
            }
        }
    }
    .team-ranking {
        &.waf-standings {
            .team-info {
                .name {
                    @include truncate-vertical(1, 16);
                    line-height: unset;
                }
            }
        }
        .table-left {
            width: 50%;
        }
        .table-right {
            width: 50%;
            .table-body {
                .text {
                    font-size: 1.6rem;
                }
            }
        }
        .origin {
            font-size: 1.4rem;
        }
        .rank {
            padding-left: 2rem;
        }
        .filter-section {
            .filter-group {
                .waf-select-box {
                    max-width: 15rem;
                }
            }
        }
    }
    .waf-statslisting {
        .filter-section {
            margin-block: var(--two-space) 7.2rem;
            .filter {
                &-wrap {
                    position: relative;
                    .tabs {
                        width: 100%;
                    }
                }
                &-body {
                    position: unset;
                    align-items: flex-end;
                    flex-basis: 100%;
                    gap: var(--one-n-half-space) var(--full-space);
                }
                &-group {
                    flex-basis: unset;
                    gap: var(--full-space);
                    .waf-select-box {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
                &-label-group {
                    margin-block: 0;
                    flex-basis: unset;
                }
            }
            .tabs {
                margin-block: 0;
                .tab-name {
                    min-width: 6.4rem;
                    height: 3rem;
                    padding: 0;
                    @include flex-config(flex, null, center, center);
                }
            }
            .label-group-head {
                display: none;
            }
        }
        .table-left {
            .table-body {
                .team-info {
                    .name {
                        font-size: 1.6rem;
                        line-height: unset;
                    }
                }
            }
        }
    }
    .event-details {
        .filter-section {
            .filter-body {
                overflow: unset;
            }
        }
    }
    .waf-standings {
        .table {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
        }
        .table-title {
            margin-bottom: var(--one-n-half-space);
        }
        .table-responsive:not(:last-child) {
            margin-bottom: 4rem;
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-standings,
    .waf-statslisting {
        .table {
            &-left {
                width: 50%;
            }
            &-right {
                width: 50%;
            }
            .image {
                width: 4.6rem;
                margin-right: var(--full-space);
            }
        }
    }
    .team-ranking {
        .table {
            &-left {
                width: 30%;
            }
            &-right {
                width: 70%;
            }
            .rank {
                max-width: 12rem;
            }
        }
    }
    .waf-statslisting {
        .tag {
            font-size: 1.4rem;
            &::before {
                margin-inline: 0 var(--half-space);
            }
        }
    }
}